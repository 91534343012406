<template lang="pug">
  div.policy
    div.container
      h1 {{ $t('policy.title') }}
      .policy__container
        div(v-html="$t('policy.thisPrivacyText')")
        h3 {{ $t('policy.collectionTitle') }}
        div(v-html="$t('policy.collectionText')")
        h3 {{ $t('policy.cookiesTitle') }}
        div(v-html="$t('policy.cookiesText')")
        h3 {{ $t('policy.dataTitle') }}
        div(v-html="$t('policy.dataText')")
        h3 {{ $t('policy.rightsTitle') }}
        div(v-html="$t('policy.rightsText')")
        h3 {{ $t('policy.resourcesTitle') }}
        p {{ $t('policy.resourcesText') }}
        h3 {{ $t('policy.updateTitle') }}
        p {{ $t('policy.updateText') }}
</template>

<script>
export default {
  name: 'PolicyPage',
  metaInfo () {
    return this.$metaDefault({ robots: 'noindex, follow' })
  }
}
</script>
